@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
html,
body {
  overflow-x: hidden;
}
}