body {
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0, 0.1)), to(rgba(0,0,0, 0.1))), url(./assets/bg.png);
  background-image: linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)), url(./assets/bg.png); */
  /* background-image: 
    linear-gradient(#793bff, transparent),
    linear-gradient(to top left, #852A7A, transparent),
    linear-gradient(to top right, #261A58, transparent);
    background-image: 
    -webkit-gradient(linear, left top, left bottom, from(#793bff), to(transparent)),
    -webkit-gradient(linear, right bottom, left top, from(#852A7A), to(transparent)),
    -webkit-gradient(linear, left bottom, right top, from(#261A58), to(transparent));  
    background-image: 
    -o-linear-gradient(#793bff, transparent),
    -o-linear-gradient(bottom right, #852A7A, transparent),
    -o-linear-gradient(bottom left, #261A58, transparent);
  background-blend-mode: screen; */

  
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

  background-size: cover;
  min-height: 100vh;
  position: relative;
  animation: AnimateBG 20s ease infinite;
}

body::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, rgba(121, 59, 255, 1), rgba(93, 46, 200, 1));
  border-radius: 10px;
	-webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
	        box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}

.title__h2 {
  font-size: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #363940;
}

.title__h2 svg, .title__h2 img {
  width: 1em;
  height: 1em;
  margin-left: 0.2em;
}

  /* ANIMATION BACKGROUND */
  .box {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border: 2px solid #9582dc;
    -webkit-animation: boxes 12s ease-in infinite;
            animation: boxes 12s ease-in infinite;
    z-index: -2;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
  }
  
  .box:nth-child(5) {
    top: 20%;
    left: 90%;
    border-color: yellow;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .box:nth-child(6) {
    top: 30%;
    left: 30%;
    border-color: green;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  .box:nth-child(7) {
    top: 60%;
    left: 90%;
    border-color: red;
    animation-delay: 3s;
  }
  
  .box:nth-child(8) {
    top: 60%;
    left: 90%;
    border-color: red;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  .box:nth-child(9) {
    top: 80%;
    left: 40%;
    border-color: cyan;
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
  }
  
  @-webkit-keyframes boxes {
    0% {
      -webkit-transform: scale(0) rotate(0);
              transform: scale(0) rotate(0);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: scale(3) rotate(960deg);
              transform: scale(3) rotate(960deg);
      opacity: 0;
    }
  }
@keyframes boxes {
    0% {
      -webkit-transform: scale(0) rotate(0);
              transform: scale(0) rotate(0);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: scale(3) rotate(960deg);
              transform: scale(3) rotate(960deg);
      opacity: 0;
    }
  }